@import '../../../../assets/scss/libs/variables';
@import '../../../../assets/scss/libs/mixins';

.grid {
  margin-top: 40px;
  padding-right: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  overflow-y: scroll;

  &::-webkit-scrollbar{
    width: 15px;
  }
  
  &::-webkit-scrollbar-track{
    background: $bg;
  }
  
  &::-webkit-scrollbar-thumb{
    background: $blue;
    border-radius: 10px;
    border: 3px solid $bg;
  }
}