@import '../../assets/scss/libs/variables';
@import '../../assets/scss/libs/mixins';

.reviews {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  @include text(40px, 56px, 700, $dark-blue);
  margin-bottom: 36px;
}

.wrapper {
  width: 100%;
  height: 100%;
  @include flex(flex-start, flex-start);
  flex-direction: column;
  overflow: hidden;

  &__loading {
    justify-content: center !important;
    align-items: center !important;
  }
}