@import './assets/scss/libs/variables';
@import './assets/scss/libs/mixins';

.app {
  @include flex();
}

.content {
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: $bg;
  padding: 40px 56px;
}