@import '../../../../assets/scss/libs/variables';
@import '../../../../assets/scss/libs/mixins';

.item {
  padding: 16px 24px;
  border: 1px solid rgba($gray, 0.4);
  background: $ghost-white;
  @include flex(flex-start, flex-start);
  flex-direction: column;
  gap: 12px;
}

.rate {
  @include flex(flex-start, center);
  gap: 8px;

  svg {
    font-size: 32px;
    color: $yellow;
  }
}

.comments {
  width: 100%;
  border-top: 1px solid rgba($gray, 0.4);
  padding-top: 12px;
  
  ul {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    gap: 4px;
  }

  li {
    @include text(16px, 20px, 600, $dark-gray);
  }
}