$blue: #4285B4;
$blue-hover: #6495ED;
$blue-button: #C5D0E6;
$dark-blue: #002F55;
$bg: #f4f7ff;
$gray: #949494;
$dark-gray: #1e1e1e;
$ghost-white: #fafcff;
$message: #edf2fe;
$red: #E32636;
$yellow: #FFCF48;