@import '../../../../assets/scss/libs/variables';
@import '../../../../assets/scss/libs/mixins';

.typing {
  padding: 6px 12px 12px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
}

.header {
  background: $ghost-white;
  padding: 12px 24px;
  border-radius: 16px;
  box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.15);
  @include flex(flex-start, center);
  gap: 32px;

  &__part {
    &:nth-child(2) {
      flex: 1;
    }
  }

  
  &__img {
    width: 84px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    border: 7px solid #fff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
  }

  &__name {
    @include text(22px, 28px, 700, $dark-blue);
  }

  &__close {
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    background: rgba($red, 0.8);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease;

    @include flex(center, center);

    &:hover {
      background: $red;
      cursor: pointer;
    }

    svg {
      font-size: 24px;
      color: #fff;
    }
  }
}

.body {
  width: 100%;
  height: 100%;
  background: $ghost-white;
  box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.15);
  padding: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  &__list {
    flex: 1;
    position: relative;
  }

  &__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__image {
    width: 360px;
    border-radius: 12px;
    box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.15);
    cursor: pointer;
  }
}

.form {
  height: 64px;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(34, 60, 80, 0.15);
  padding: 12px 20px;
  border-radius: 12px;
  @include flex();
  gap: 12px;
}

.input {
  flex: 1;
  outline: none;

  @include text(16px, 20px, 600, $blue);

  &::placeholder {
    color: $gray;
    font-weight: 400;
  }
}

.submit {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: $blue-hover;
  color: $ghost-white;
  @include flex(center, center);

  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    background: $blue;
  }
}